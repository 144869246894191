import axios from "axios";

export default axios.create({
  baseURL: `${process.env.REACT_APP_TEOPAGOS_URI}`,
  //withCredentials: true,
  crossDomain: true,
  headers: { 'Content-Type': 'application/json' },
  validateStatus: (status) => status < 500,
});

// const instance = axios.create({
//   baseURL: `${process.env.REACT_APP_TEOPAGOS_URI}`,
//   withCredentials: true,
//   headers: { 'Content-Type': 'application/json' },
//   validateStatus: (status) => status < 500,
// });

// //axios.defaults.headers.post['Content-Type'] = 'application/json';
// //axios.defaults.withCredentials = true;
// axios.defaults.crossDomain = true;

// export default instance;
