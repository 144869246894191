import React, { useContext } from "react";
import styled from "styled-components/macro";
import { Power } from "react-feather";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@material-ui/core";

// import { signOut } from "../redux/actions/authActions";
import { AuthContext } from "../redux/store/auth-context";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function UserDropdown() {
  const auth = useContext(AuthContext);
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    // await dispatch(signOut(auth.teoId));
    auth.logout(auth.teoId); 
    // history.push("/");
  };

  return (
    <React.Fragment>
      <Tooltip title="Mi Cuenta">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          style={{ color: 'white' }}
        >
          <Power />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem onClick={closeMenu}>Perfil</MenuItem>
        <MenuItem onClick={handleSignOut}>Salir</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default UserDropdown;
