import * as types from "../../constants";

export default function reducer(state = {}, actions) {
    switch (actions.type) {
        case types.DASH_INFO_SUCCESS:
            console.log('reducers data');
            console.log(actions);
            return {
                ...state,
                data: actions.response.data,
            };

        default:
            return state;
    }
}
