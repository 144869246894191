import * as types from "../../constants";
const initState = {
  rows: "",
  columns: "",
};

export default function reducer(state = initState, actions) {
  switch (actions.type) {
    case types.COLLABORATORS_RESTART_RESPONSE:
      return {
        ...state,
        rows: "",
        columns: "",
      };

    case types.COLLABORATORS_LIST_SUCCESS:
      console.log("reducers Collaborators list data");
      console.log(actions.response);
      return {
        ...state,
        rows: actions.response.rows,
        columns: actions.response.columns,
      };

    default:
      return state;
  }
}
