import { combineReducers } from "redux";

import themeReducer from "./themeReducer";
import authReducer from "./authReducer";
import dashReducer from "./dashReducer";
import permissionsReducer from "./permissionsReducer";
//Conf Reducers
import confGenReducer from "./confGenReducer";
//Habilitaciones Reducer
import requestsReducer from "./requestsReducer";
import requestsDetailsReducer from "./requestsDetailsReducer";
//Transfers Reducer
import transfersReducer from "./transfersReducer";
import transfersReportReducer from "./transfersReportReducer";
//Operations Reducer
import operationsReducer from "./operationsReducer";
//Operations Reducer
import usersReducer from "./usersReducer";
//Invoices Reducer
import invoicesReducer from "./invoicesReducer";
import invoicesReportReducer from "./invoicesReportReducer";
//Movements Reducer
import movementsReducer from "./movementsReducer";
//customSettingsUser Reducer
import customSettingsUserReducer from "./customSettingsUserReducer";
//customSettingsUser Reducer
import collaboratorsReducer from "./collaboratorsReducer";
//customerBusiness Reducer
import customerBusinessReducer from "./customerBusinessReducer";
//wallets Reducer
import walletsReducer from "./walletsReducer";
export const rootReducer = combineReducers({
  themeReducer,
  authReducer,
  dashReducer,
  confGenReducer,
  permissionsReducer,
  requestsReducer,
  requestsDetailsReducer,
  transfersReducer,
  transfersReportReducer,
  operationsReducer,
  usersReducer,
  invoicesReducer,
  invoicesReportReducer,
  movementsReducer,
  customSettingsUserReducer,
  collaboratorsReducer,
  customerBusinessReducer,
  walletsReducer,
});
