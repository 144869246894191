// Theme
export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

// Auth
export const AUTH_SIGN_IN_RESTART_RESPONSE = "AUTH_SIGN_IN_RESTART_RESPONSE";
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT_REQUEST = "AUTH_SIGN_OUT";
export const AUTH_SIGN_OUT_FAILURE = "AUTH_SIGN_OUT_FAILURE";
export const AUTH_SIGN_OUT_SUCCESS = "AUTH_SIGN_OUT_FAILURE";
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";

// Permissions
export const LIST_PERMISSIONS_RESTART_RESPONSE =
  "LIST_PERMISSIONS_RESTART_RESPONSE";
export const LIST_PERMISSIONS_REQUEST = "LIST_PERMISSIONS_REQUEST";
export const LIST_PERMISSIONS_SUCCESS = "LIST_PERMISSIONS_SUCCESS";
export const LIST_PERMISSIONS_FAILURE = "LIST_PERMISSIONS_FAILURE";

// Dashboard
export const DASH_INFO_REQUEST = "DASH_INFO_REQUEST";
export const DASH_INFO_SUCCESS = "DASH_INFO_SUCCESS";
export const DASH_INFO_FAILURE = "DASH_INFO_FAILURE";

//CSV DOWNLOAD
// Dashboard
export const CSVDOWNLOAD_REQUEST = "CSVDOWNLOAD_REQUEST";
export const CSVDOWNLOAD_SUCCESS = "CSVDOWNLOAD_SUCCESS";
export const CSVDOWNLOAD_FAILURE = "CSVDOWNLOAD_FAILURE";

//CONFIGURATIONS
export const CONF_GEN_RESTART_RESPONSE = "CONF_GEN_RESTART_RESPONSE";
//General List
export const CONF_GEN_LIST_REQUEST = "CONF_GEN_LIST_REQUEST";
export const CONF_GEN_LIST_SUCCESS = "CONF_GEN_LIST_SUCCESS";
export const CONF_GEN_LIST_FAILURE = "CONF_GEN_LIST_FAILURE";
//General Create
export const CONF_GEN_CREATE_REQUEST = "CONF_GEN_CREATE_REQUEST";
export const CONF_GEN_CREATE_SUCCESS = "CONF_GEN_CREATE_SUCCESS";
export const CONF_GEN_CREATE_FAILURE = "CONF_GEN_CREATE_FAILURE";
//General Update
export const CONF_GEN_UPDATE_REQUEST = "CONF_GEN_UPDATE_REQUEST";
export const CONF_GEN_UPDATE_SUCCESS = "CONF_GEN_UPDATE_SUCCESS";
export const CONF_GEN_UPDATE_FAILURE = "CONF_GEN_UPDATE_FAILURE";
//General Delete
export const CONF_GEN_DELETE_REQUEST = "CONF_GEN_DELETE_REQUEST";
export const CONF_GEN_DELETE_SUCCESS = "CONF_GEN_DELETE_SUCCESS";
export const CONF_GEN_DELETE_FAILURE = "CONF_GEN_DELETE_FAILURE";

//HABILITACIONES
export const REQUESTS_RESTART_RESPONSE = "REQUESTS_RESTART_RESPONSE";
//Habilitaciones List
export const REQUESTS_LIST_REQUEST = "REQUESTS_LIST_REQUEST";
export const REQUESTS_LIST_SUCCESS = "REQUESTS_LIST_SUCCESS";
export const REQUESTS_LIST_FAILURE = "REQUESTS_LIST_FAILURE";
//Habilitaciones details
export const REQUESTS_DETAILS_REQUEST = "REQUESTS_DETAILS_REQUEST";
export const REQUESTS_DETAILS_SUCCESS = "REQUESTS_DETAILS_SUCCESS";
export const REQUESTS_DETAILS_FAILURE = "REQUESTS_DETAILS_FAILURE";
//Habilitaciones Create
export const REQUESTS_CREATE_REQUEST = "REQUESTS_CREATE_REQUEST";
export const REQUESTS_CREATE_SUCCESS = "REQUESTS_CREATE_SUCCESS";
export const REQUESTS_CREATE_FAILURE = "REQUESTS_CREATE_FAILURE";
//Habilitaciones Update
export const REQUESTS_UPDATE_REQUEST = "REQUESTS_UPDATE_REQUEST";
export const REQUESTS_UPDATE_SUCCESS = "REQUESTS_UPDATE_SUCCESS";
export const REQUESTS_UPDATE_FAILURE = "REQUESTS_UPDATE_FAILURE";
//Habilitaciones Delete
export const REQUESTS_DELETE_REQUEST = "REQUESTS_DELETE_REQUEST";
export const REQUESTS_DELETE_SUCCESS = "REQUESTS_DELETE_SUCCESS";
export const REQUESTS_DELETE_FAILURE = "REQUESTS_DELETE_FAILURE";

//OPERACIONES
export const OPERATIONS_RESTART_RESPONSE = "OPERATIONS_RESTART_RESPONSE";
//Operaciones List
export const OPERATIONS_LIST_REQUEST = "OPERATIONS_LIST_REQUEST";
export const OPERATIONS_LIST_SUCCESS = "OPERATIONS_LIST_SUCCESS";
export const OPERATIONS_LIST_FAILURE = "OPERATIONS_LIST_FAILURE";
//Operaciones Create
export const OPERATIONS_CREATE_REQUEST = "OPERATIONS_CREATE_REQUEST";
export const OPERATIONS_CREATE_SUCCESS = "OPERATIONS_CREATE_SUCCESS";
export const OPERATIONS_CREATE_FAILURE = "OPERATIONS_CREATE_FAILURE";
//Operaciones Update
export const OPERATIONS_UPDATE_REQUEST = "OPERATIONS_UPDATE_REQUEST";
export const OPERATIONS_UPDATE_SUCCESS = "OPERATIONS_UPDATE_SUCCESS";
export const OPERATIONS_UPDATE_FAILURE = "OPERATIONS_UPDATE_FAILURE";
//Operaciones Delete
export const OPERATIONS_DELETE_REQUEST = "OPERATIONS_DELETE_REQUEST";
export const OPERATIONS_DELETE_SUCCESS = "OPERATIONS_DELETE_SUCCESS";
export const OPERATIONS_DELETE_FAILURE = "OPERATIONS_DELETE_FAILURE";

//TRANSFERENCIAS
export const TRANSFERS_RESTART_RESPONSE = "TRANSFERS_RESTART_RESPONSE";
//Transferencias List
export const TRANSFERS_LIST_REQUEST = "TRANSFERS_LIST_REQUEST";
export const TRANSFERS_LIST_SUCCESS = "TRANSFERS_LIST_SUCCESS";
export const TRANSFERS_LIST_FAILURE = "TRANSFERS_LIST_FAILURE";
//Transferencias Create
export const TRANSFERS_CREATE_REQUEST = "TRANSFERS_CREATE_REQUEST";
export const TRANSFERS_CREATE_SUCCESS = "TRANSFERS_CREATE_SUCCESS";
export const TRANSFERS_CREATE_FAILURE = "TRANSFERS_CREATE_FAILURE";
//Transferencias Update
export const TRANSFERS_UPDATE_REQUEST = "TRANSFERS_UPDATE_REQUEST";
export const TRANSFERS_UPDATE_SUCCESS = "TRANSFERS_UPDATE_SUCCESS";
export const TRANSFERS_UPDATE_FAILURE = "TRANSFERS_UPDATE_FAILURE";
//Transferencias Delete
export const TRANSFERS_DELETE_REQUEST = "TRANSFERS_DELETE_REQUEST";
export const TRANSFERS_DELETE_SUCCESS = "TRANSFERS_DELETE_SUCCESS";
export const TRANSFERS_DELETE_FAILURE = "TRANSFERS_DELETE_FAILURE";

//USERS
export const USERS_RESTART_RESPONSE = "USERS_RESTART_RESPONSE";
//Users List
export const USERS_LIST_REQUEST = "USERS_LIST_REQUEST";
export const USERS_LIST_SUCCESS = "USERS_LIST_SUCCESS";
export const USERS_LIST_FAILURE = "USERS_LIST_FAILURE";
//Users Create
export const USERS_CREATE_REQUEST = "USERS_CREATE_REQUEST";
export const USERS_CREATE_SUCCESS = "USERS_CREATE_SUCCESS";
export const USERS_CREATE_FAILURE = "USERS_CREATE_FAILURE";
//Users Update
export const USERS_UPDATE_REQUEST = "USERS_UPDATE_REQUEST";
export const USERS_UPDATE_SUCCESS = "USERS_UPDATE_SUCCESS";
export const USERS_UPDATE_FAILURE = "USERS_UPDATE_FAILURE";
//Users Delete
export const USERS_DELETE_REQUEST = "USERS_DELETE_REQUEST";
export const USERS_DELETE_SUCCESS = "USERS_DELETE_SUCCESS";
export const USERS_DELETE_FAILURE = "USERS_DELETE_FAILURE";

//Facturas
export const INVOICES_RESTART_RESPONSE = "INVOICES_RESTART_RESPONSE";
//Invoices List
export const INVOICES_LIST_REQUEST = "INVOICES_LIST_REQUEST";
export const INVOICES_LIST_SUCCESS = "INVOICES_LIST_SUCCESS";
export const INVOICES_LIST_FAILURE = "INVOICES_LIST_FAILURE";
//Invoices Create
export const INVOICES_CREATE_REQUEST = "INVOICES_CREATE_REQUEST";
export const INVOICES_CREATE_SUCCESS = "INVOICES_CREATE_SUCCESS";
export const INVOICES_CREATE_FAILURE = "INVOICES_CREATE_FAILURE";
//Invoices Update
export const INVOICES_UPDATE_REQUEST = "INVOICES_UPDATE_REQUEST";
export const INVOICES_UPDATE_SUCCESS = "INVOICES_UPDATE_SUCCESS";
export const INVOICES_UPDATE_FAILURE = "INVOICES_UPDATE_FAILURE";
//Invoices Delete
export const INVOICES_DELETE_REQUEST = "INVOICES_DELETE_REQUEST";
export const INVOICES_DELETE_SUCCESS = "INVOICES_DELETE_SUCCESS";
export const INVOICES_DELETE_FAILURE = "INVOICES_DELETE_FAILURE";
//Movements List
export const MOVEMENTS_RESTART_RESPONSE = "MOVEMENTS_RESTART_RESPONSE";
export const MOVEMENTS_LIST_REQUEST = "MOVEMENTS_LIST_REQUEST";
export const MOVEMENTS_LIST_SUCCESS = "MOVEMENTS_LIST_SUCCESS";
export const MOVEMENTS_LIST_FAILURE = "MOVEMENTS_LIST_FAILURE";

//Custom Setting User
export const CUSTOM_SETTINGS_USER_RESTART_RESPONSE =
  "CUSTOM_SETTINGS_USER_RESTART_RESPONSE";
//Custom Setting User List
export const CUSTOM_SETTINGS_USER_LIST_REQUEST =
  "CUSTOM_SETTINGS_USER_LIST_REQUEST";
export const CUSTOM_SETTINGS_USER_LIST_SUCCESS =
  "CUSTOM_SETTINGS_USER_LIST_SUCCESS";
export const CUSTOM_SETTINGS_USER_LIST_FAILURE =
  "CUSTOM_SETTINGS_USER_LIST_FAILURE";
//Custom Setting User Create
export const CUSTOM_SETTINGS_USER_CREATE_REQUEST =
  "CUSTOM_SETTINGS_USER_CREATE_REQUEST";
export const CUSTOM_SETTINGS_USER_CREATE_SUCCESS =
  "CUSTOM_SETTINGS_USER_CREATE_SUCCESS";
export const CUSTOM_SETTINGS_USER_CREATE_FAILURE =
  "CUSTOM_SETTINGS_USER_CREATE_FAILURE";
//Custom Setting User Update
export const CUSTOM_SETTINGS_USER_UPDATE_REQUEST =
  "CUSTOM_SETTINGS_USER_UPDATE_REQUEST";
export const CUSTOM_SETTINGS_USER_UPDATE_SUCCESS =
  "CUSTOM_SETTINGS_USER_UPDATE_SUCCESS";
export const CUSTOM_SETTINGS_USER_UPDATE_FAILURE =
  "CUSTOM_SETTINGS_USER_UPDATE_FAILURE";
//Custom Setting User Delete
export const CUSTOM_SETTINGS_USER_DELETE_REQUEST =
  "CUSTOM_SETTINGS_USER_DELETE_REQUEST";
export const CUSTOM_SETTINGS_USER_DELETE_SUCCESS =
  "CUSTOM_SETTINGS_USER_DELETE_SUCCESS";
export const CUSTOM_SETTINGS_USER_DELETE_FAILURE =
  "CUSTOM_SETTINGS_USER_DELETE_FAILURE";

//Collaborators
//Collaborators List
export const COLLABORATORS_RESTART_RESPONSE = "COLLABORATORS_RESTART_RESPONSE";
export const COLLABORATORS_LIST_REQUEST = "COLLABORATORS_LIST_REQUEST";
export const COLLABORATORS_LIST_SUCCESS = "COLLABORATORS_LIST_SUCCESS";
export const COLLABORATORS_LIST_FAILURE = "COLLABORATORS_LIST_FAILURE";

//Collaborators Create
export const COLLABORATORS_CREATE_REQUEST = "COLLABORATORS_CREATE_REQUEST";
export const COLLABORATORS_CREATE_SUCCESS = "COLLABORATORS_CREATE_SUCCESS";
export const COLLABORATORS_CREATE_FAILURE = "COLLABORATORS_CREATE_FAILURE";
//Collaborators Update
export const COLLABORATORS_UPDATE_REQUEST = "COLLABORATORS_UPDATE_REQUEST";
export const COLLABORATORS_UPDATE_SUCCESS = "COLLABORATORS_UPDATE_SUCCESS";
export const COLLABORATORS_UPDATE_FAILURE = "COLLABORATORS_UPDATE_FAILURE";
//Collaborators Delete
export const COLLABORATORS_DELETE_REQUEST = "COLLABORATORS_DELETE_REQUEST";
export const COLLABORATORS_DELETE_SUCCESS = "COLLABORATORS_DELETE_SUCCESS";
export const COLLABORATORS_DELETE_FAILURE = "COLLABORATORS_DELETE_FAILURE";

//Customer Business
//Customer Business List
export const CUSTOMER_BUSINESS_RESTART_RESPONSE =
  "CUSTOMER_BUSINESS_RESTART_RESPONSE";
export const CUSTOMER_BUSINESS_LIST_REQUEST = "CUSTOMER_BUSINESS_LIST_REQUEST";
export const CUSTOMER_BUSINESS_LIST_SUCCESS = "CUSTOMER_BUSINESS_LIST_SUCCESS";
export const CUSTOMER_BUSINESS_LIST_FAILURE = "CUSTOMER_BUSINESS_LIST_FAILURE";

//Customer Business Create
export const CUSTOMER_BUSINESS_CREATE_REQUEST =
  "CUSTOMER_BUSINESS_CREATE_REQUEST";
export const CUSTOMER_BUSINESS_CREATE_SUCCESS =
  "CUSTOMER_BUSINESS_CREATE_SUCCESS";
export const CUSTOMER_BUSINESS_CREATE_FAILURE =
  "CUSTOMER_BUSINESS_CREATE_FAILURE";
//Customer Business Update
export const CUSTOMER_BUSINESS_UPDATE_REQUEST =
  "CUSTOMER_BUSINESS_UPDATE_REQUEST";
export const CUSTOMER_BUSINESS_UPDATE_SUCCESS =
  "CUSTOMER_BUSINESS_UPDATE_SUCCESS";
export const CUSTOMER_BUSINESS_UPDATE_FAILURE =
  "CUSTOMER_BUSINESS_UPDATE_FAILURE";
//Customer Business Delete
export const CUSTOMER_BUSINESS_DELETE_REQUEST =
  "CUSTOMER_BUSINESS_DELETE_REQUEST";
export const CUSTOMER_BUSINESS_DELETE_SUCCESS =
  "CUSTOMER_BUSINESS_DELETE_SUCCESS";
export const CUSTOMER_BUSINESS_DELETE_FAILURE =
  "CUSTOMER_BUSINESS_DELETE_FAILURE";
//BILLETERAS
export const WALLETS_RESTART_RESPONSE = "WALLETS_RESTART_RESPONSE";
//Billeteras List
export const WALLETS_LIST_REQUEST = "WALLETS_LIST_REQUEST";
export const WALLETS_LIST_SUCCESS = "WALLETS_LIST_SUCCESS";
export const WALLETS_LIST_FAILURE = "WALLETS_LIST_FAILURE";
//Billeteras Create
export const WALLETS_CREATE_REQUEST = "WALLETS_CREATE_REQUEST";
export const WALLETS_CREATE_SUCCESS = "WALLETS_CREATE_SUCCESS";
export const WALLETS_CREATE_FAILURE = "WALLETS_CREATE_FAILURE";
//Billeteras Update
export const WALLETS_UPDATE_REQUEST = "WALLETS_UPDATE_REQUEST";
export const WALLETS_UPDATE_SUCCESS = "WALLETS_UPDATE_SUCCESS";
export const WALLETS_UPDATE_FAILURE = "WALLETS_UPDATE_FAILURE";
//Billeteras Delete
export const WALLETS_DELETE_REQUEST = "WALLETS_DELETE_REQUEST";
export const WALLETS_DELETE_SUCCESS = "WALLETS_DELETE_SUCCESS";
export const WALLETS_DELETE_FAILURE = "WALLETS_DELETE_FAILURE";
