import * as types from "../../constants";
const initState = {
  rows: "",
};

export default function reducer(state = initState, actions) {
  switch (actions.type) {
    case types.CUSTOM_SETTINGS_USER_RESTART_RESPONSE:
      return {
        ...state,
        rows: "",
      };

    case types.CUSTOM_SETTINGS_USER_LIST_SUCCESS:
      console.log("reducers CUSTOM SETTINGS USER list data");
      console.log(actions.response);
      return {
        ...state,
        rows: actions.response,
      };

    default:
      return state;
  }
}
