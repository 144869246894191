import axios from "../utils/axios";

export function actions(credentials) {
  const { url, token, action, data } = credentials;
  console.log(action + ":");
  if (action === "list") {
    return new Promise((resolve, reject) => {
      axios
        .get(`/admin/${url}`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            console.log("service data");
            console.log(response.data);
            resolve(response.data);
          }
          reject(response.data.errors);
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
          reject(error);
        });
    });
  }
  if (action === "update") {
    return new Promise((resolve, reject) => {
      axios
        .put(`/admin/${url}`, data, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            console.log(action + "data:");
            console.log(response.data);
            resolve(response.data);
          }
          reject(response.data.errors);
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
          reject(error);
        });
    });
  }
  if (action === "create") {
    return new Promise((resolve, reject) => {
      axios
        .post(`/admin/${url}`, data, {
          headers: {
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          if (response.status === 201) {
            console.log(action + "data:");
            console.log(response.data);
            resolve(response.data);
          }
          reject(response.data.errors);
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
          reject(error);
        });
    });
  }
}
