import * as types from "../../constants";
const initState = {
    rows: "",
    columns: "",
    totals: "",
    statuses: "",
    methods: "",
    permissions: "",
    details: "",
    csv_url: false
};

export default function reducer(state = initState, actions) {
    switch (actions.type) {
        case types.TRANSFERS_RESTART_RESPONSE:
            return {
                ...state,
                rows: "",
                columns: "",
                totals: "",
                statuses: "",
                methods: "",
                permissions: "",
                details: "",
                csv_url: false
            };

        case types.TRANSFERS_LIST_SUCCESS:
            console.log("reducers transfers list data");
            console.log(actions.response);
            return {
                ...state,
                rows: actions.response.rows.results,
                columns: actions.response.columns,
                totals: actions.response.totals,
                statuses: actions.response.statuses,
                methods: actions.response.methods,
                permissions: actions.response.permissions,
                details: actions.response.rows,
                csv_url: actions.response.url_datafile
            };

        case types.TRANSFERS_UPDATE_SUCCESS:
            console.log("reducers transfers updated data");
            console.log(actions.response);
            return actions.response;

        default:
            return state;
    }
}
