import * as types from "../../constants";
const initState = {
  rows: "",
  columns: "",
  options: "",
  values: "",
  attachments: "",
  data: "",
  general_status: "",
  rucs: "",
};

export default function reducer(state = initState, actions) {
  switch (actions.type) {
    case types.REQUESTS_RESTART_RESPONSE:
      console.log("restart reducers req details data");
      return {
        ...state,
        rows: "",
        columns: "",
        options: "",
        values: "",
        attachments: "",
        general_status: "",
        rucs: "",
      };

    case types.REQUESTS_DETAILS_SUCCESS:
      console.log("reducers req details data");
      console.log(actions.response);
      return {
        ...state,
        rows: actions.response.rows,
        columns: actions.response.columns,
        options: actions.response.options,
        values: actions.response.rows_values,
        attachments: actions.response.attachments,
        general_status: actions.response.general_status,
        rucs: actions.response.rucs,
      };
    case types.REQUESTS_UPDATE_SUCCESS:
      console.log("reducers req update success data");
      console.log(actions.response);
      return {
        ...state,
        attachments: actions.response.attachments,
        data: actions.response.data,
      };
    default:
      return state;
  }
}
