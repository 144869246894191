import axios from "../utils/axios";

export function list(credentials) {
    const { url, token, data } = credentials;
    return new Promise((resolve, reject) => {
        axios
            .get(`/admin/${url}`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
            .then((response) => {
                if (response.status === 200) {
                    console.log('service data');
                    console.log(response.data);
                    resolve(response.data);
                }
                reject(response.data.errors);
            })
            .catch((error) => {
                console.log('error');
                console.log(error);
                reject(error);
            });
    });
}

