import * as types from "../../constants";
const initState = {
    permissions: "",
    errors: ""
}

export default function reducer(state = initState, actions) {
    switch (actions.type) {
        case types.LIST_PERMISSIONS_RESTART_RESPONSE:
            return {
                ...state,
                permissions: "",
                errors: ""
            };

        case types.LIST_PERMISSIONS_SUCCESS:
            console.log('reducers permissions list data');
            console.log(actions.response);
            return {
                ...state,
                permissions: actions.response.permissions,
            };
        
        case types.LIST_PERMISSIONS_FAILURE:
            console.log('reducers permissions error');
            console.log(actions);
            return {
                ...state,
                permissions: undefined,
                errors: actions.error
            };

        default:
            return state;
    }
}
