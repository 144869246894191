import * as types from "../../constants";
const initState = {
    rows: "",
    columns: "",
    options: "",
    details: "",
    csv_url: false
}

export default function reducer(state = initState, actions) {
    switch (actions.type) {
        case types.OPERATIONS_RESTART_RESPONSE:
            return {
                ...state,
                rows: "",
                columns: "",
                options: "",
                details: "",
                csv_url: false
            };

        case types.OPERATIONS_LIST_SUCCESS:
            console.log('reducers operations list data');
            console.log(actions.response);
            return {
                ...state,
                rows: actions.response.rows.results,
                columns: actions.response.columns,
                options: actions.response.options,
                details: actions.response.rows,
                csv_url: actions.response.url_datafile
            };

        default:
            return state;
    }
}
