import * as types from "../../constants";
const initState = {
  rows: "",
  columns: "",
  details: "",
  planes: "",
  statuses: "",
  success: "",
  rucChecked: true,
  data_updated: false,
  days_week: "",
  products_plan: "",
  products_plan_initial: "",
  csv_url: false,
  amount_available: "",
};

export default function reducer(state = initState, actions) {
  switch (actions.type) {
    case types.CUSTOMER_BUSINESS_RESTART_RESPONSE:
      return {
        ...state,
        rows: "",
        details: "",
        columns: "",
        planes: "",
        statuses: "",
        success: "",
        rucChecked: true,
        data_updated: false,
        days_week: "",
        products_plan: "",
        products_plan_initial: "",
        csv_url: false,
        amount_available: "",
      };

    case types.CUSTOMER_BUSINESS_LIST_SUCCESS:
      console.log("reducers CUSTOMER_BUSINESS list data");
      console.log(actions.response);
      return {
        ...state,
        rows: actions.response.rows?.results,
        columns: actions.response.columns,
        details: actions.response.rows,
        success: "",
        planes: actions.response.planes,
        statuses: actions.response.statuses,
        rucChecked: actions.response.error_ruc,
        data_updated: false,
        days_week: actions.response.days_week,
        products_plan: actions.response.products_plan,
        products_plan_initial: actions.response.products_plan_initial,
        csv_url: actions.response.url_datafile,
        amount_available: actions.response.business_amount_available,
        api: actions.response.api,
      };
    case types.CUSTOMER_BUSINESS_CREATE_SUCCESS:
      console.log("reducers CUSTOMER_BUSINESS create data");
      console.log(actions, "actions customerbusinessreducer");
      return {
        ...state,
        data_updated: actions.response.data,
        success: true,
      };
    case types.CUSTOMER_BUSINESS_CREATE_FAILURE:
      console.log("reducers CUSTOMER_BUSINESS create data");
      console.log(actions);
      return {
        ...state,
        success: false,
        data_updated: false,
      };
    case types.CUSTOMER_BUSINESS_UPDATE_SUCCESS:
      console.log("reducers CUSTOMER_BUSINESS update data");
      console.log(actions, "actions customerbusinessreducer");
      return {
        ...state,
        success: actions.response.success,
      };
    case types.CUSTOMER_BUSINESS_UPDATE_FAILURE:
      console.log("reducers CUSTOMER_BUSINESS update data");
      console.log(actions);
      return {
        ...state,
        success: false,
      };

    default:
      return state;
  }
}
