import * as types from "../../constants";
const initState = {
  rows: "",
  rows_grouped: "",
  columns: "",
  business: "",
  details: "",
  totals: "",
  incomes: "",
  amount_available: ""
};

export default function reducer(state = initState, actions) {
  switch (actions.type) {
    case types.MOVEMENTS_RESTART_RESPONSE:
      return {
        ...state,
        rows: "",
        rows_grouped: "",
        columns: "",
        business: "",
        details: "",
        totals: "",
        incomes: "",
        amount_available: ""
      };

    case types.MOVEMENTS_LIST_SUCCESS:
      console.log("reducers MOVEMENTS list data");
      console.log(actions.response);
      return {
        ...state,
        rows: actions.response.rows,
        rows_grouped: actions.response.rows_grouped,
        columns: actions.response.columns,
        business: actions.response.business,
        details: actions.response.details,
        totals: actions.response.totals,
        incomes: actions.response.incomes,
        amount_available: actions.response.amount_available
      };

    default:
      return state;
  }
}
