import * as types from "../../constants";

const initState = {
  user: "",
  errors: ""
}

export default function reducer(state = initState, actions) {
  switch (actions.type) {
    case types.AUTH_SIGN_IN_RESTART_RESPONSE:
      return {
        ...state,
        user: "",
        errors: "",
      };

    case types.AUTH_SIGN_IN_SUCCESS:
      console.log('reducers data');
      console.log(actions);
      return {
        ...state,
        user: {
          id: actions.response.user._id,
          teoId: actions.response.user.teoid,
          admin: actions.response.admin,
          token: actions.response.token,
          teoIdToken: actions.response.teoIdToken,
          name: actions.response.user.name,
          lastname: actions.response.user.lastname,
          email: actions.response.user.email,
          profile: actions.response.user.profile,
          photo: actions.response.user.photo
        }
      };

    case types.AUTH_SIGN_IN_FAILURE:
      console.log('reducers data error');
      console.log(actions);
      return {
        ...state,
        user: undefined,
        errors: actions.error
      };

    case types.AUTH_SIGN_OUT_SUCCESS:
      return {
        ...state,
        user: undefined,
      };

    case types.AUTH_SIGN_OUT_FAILURE:
      console.log('reducers data error');
      console.log(actions);
      return {
        ...state,
        user: undefined,
        errors: actions.error
      };

    default:
      return state;
  }
}
