import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components/macro";
import { rgba } from "polished";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { darken } from "polished";
import PerfectScrollbar from "react-perfect-scrollbar";
import "../vendor/perfect-scrollbar.css";
import { useDispatch, useSelector } from "react-redux";
import { permissions } from "../redux/actions/permissionsActions";
import { AuthContext } from "../redux/store/auth-context";
import UserDropdown from "./UserDropdown";

import { spacing } from "@material-ui/system";

import {
  Badge,
  Box as MuiBox,
  Chip,
  Grid,
  Avatar,
  Collapse,
  Drawer as MuiDrawer,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";

import { ExpandLess, ExpandMore } from "@material-ui/icons";

import { green } from "@material-ui/core/colors";

import { sidebarRoutes as routes } from "../routes/index";

import { ReactComponent as Logo } from "../vendor/logo.svg";

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;

const Brand = styled(ListItem)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(6)}px;
  justify-content: center;
  cursor: pointer;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const BrandIcon = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(2)}px;
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 32px;
  height: 32px;
`;

const BrandChip = styled(Chip)`
  background-color: ${green[700]};
  border-radius: 5px;
  color: ${(props) => props.theme.palette.common.white};
  font-size: 55%;
  height: 18px;
  margin-left: 2px;
  margin-top: -16px;
  padding: 3px 0;

  span {
    padding-left: ${(props) => props.theme.spacing(1.375)}px;
    padding-right: ${(props) => props.theme.spacing(1.375)}px;
  }
`;

const Category = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  padding-left: ${(props) => props.theme.spacing(8)}px;
  padding-right: ${(props) => props.theme.spacing(7)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
    darken(0.03, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)}px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)`
  padding-left: ${(props) => props.theme.spacing(17.5)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;

  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &:hover {
    background-color: ${(props) =>
    darken(0.015, props.theme.sidebar.background)};
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
    darken(0.03, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 28px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(7)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;

const SidebarFooter = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}px
    ${(props) => props.theme.spacing(4)}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const SidebarFooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const SidebarFooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

const SidebarFooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)}px;
  span {
    background-color: ${(props) =>
    props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const SidebarCategory = ({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  ...rest
}) => {
  return (
    <Category {...rest}>
      {icon}
      <CategoryText>{name}</CategoryText>
      {isCollapsable ? (
        isOpen ? (
          <CategoryIconMore />
        ) : (
          <CategoryIconLess />
        )
      ) : null}
      {badge ? <CategoryBadge label={badge} /> : ""}
    </Category>
  );
};

const SidebarLink = ({ name, to, badge, icon }) => {
  return (
    <Link
      button
      dense
      component={NavLink}
      exact
      to={to}
      activeClassName="active"
    >
      <LinkText>{name}</LinkText>
      {badge ? <LinkBadge label={badge} /> : ""}
    </Link>
  );
};

const Sidebar = ({ classes, staticContext, location, ...rest }) => {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const dispatch = useDispatch();
  useEffect(() => {
    if (auth.token) {
      console.log("permission use efect");
      try {
        console.log("permission try dispatch");
        dispatch(
          permissions({
            url: "permissions/",
            token: auth.token,
            action: "list",
          })
        );
      } catch (error) {
        console.log(error);
      }
    }
  }, [auth.token]);
  const permission = useSelector(
    (state) => state.permissionsReducer.permissions
  );
  const errors = useSelector((state) => state.permissionsReducer.errors);
  const initOpenRoutes = () => {
    /* Open collapse element that matches current url */
    const pathName = location.pathname;

    let _routes = {};
    routes.forEach((route, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/";

      _routes = Object.assign({}, _routes, {
        [index]: isActive || isOpen || isHome,
      });
    });

    return _routes;
  };
  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());

  const toggle = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );
  };

  if (errors) {
    if (errors.field === "permissions") {
      history.push("/signin", { errors: errors });
    }
  }

  const getBadge = (category, pending_categories, permissions) => {
    let component_category = permissions.filter(p => p.component === pending_categories.find(pc => pc.path === category.path)?.id)
    if (component_category.length > 0) {
      return component_category[0].number_pendings > 0 ? component_category[0].number_pendings : null
    }
    return null
  }

  return (
    <Drawer variant="permanent" {...rest}>
      <Brand component={NavLink} to="/" button>
        <BrandIcon /> <Box ml={1}>TeoPagos Dash</Box>
      </Brand>
      <Scrollbar>
        <List disablePadding>
          <Items>
            {permission &&
              routes.map((category, index) => {
                const pending_categories = [{ id: 'Acreditaciones', name: 'Acreditaciones', path: '/pendings/transfers' }, { id: 'Centro de Facturación', name: 'Facturas', path: '/pendings/invoices' }, { id: "Billeteras", name: 'Confirmar cobros', path: '/pendings/wallets' }, { id: "Usuarios", name: 'Comercios', path: '/pendings/customerbusiness' }]
                let permiso = permission.filter(
                  (rd) => rd.component === category.id
                )[0];
                let hasPermission =
                  permiso &&
                  permiso.permissions.some((p) =>
                    p.hasOwnProperty("view") ? true : false
                  );
                //Businesss permisions
                const hasBusiness = permission.filter(
                  (rd) => rd.component === "Usuarios"
                )[0];
                // User permisions
                const hasUser = permission.filter(
                  (rd) => rd.component === "Usuarios"
                )[0];
                const hasUserActive = permission.filter(
                  (rd) => rd.component === "Usuarios"
                )[0];
                const hasUserInactive = permission.filter(
                  (rd) => rd.component === "Usuarios"
                )[0];
                const hasUserPending = permission.filter(
                  (rd) => rd.component === "Usuarios"
                )[0];
                // General permisions
                const hasGeneral = permission.filter(
                  (rd) => rd.component === "General"
                )[0];
                const hasEstado = permission.filter(
                  (rd) => rd.component === "Estados"
                )[0];
                const hasRoles = permission.filter(
                  (rd) => rd.component === "Roles"
                )[0];
                // Acreditations permisions
                const hasToTransfers = permission.filter(
                  (rd) => rd.component === "Acreditaciones"
                )[0];
                const hasRedyToTransfers = permission.filter(
                  (rd) => rd.component === "Acreditaciones"
                )[0];
                const hasTransfereds = permission.filter(
                  (rd) => rd.component === "Acreditaciones"
                )[0];
                // Invoices permisions
                const hasInvoices = permission.filter(
                  (rd) => rd.component === "Centro de Facturación"
                )[0];
                // has permissions ?
                hasPermission =
                  category.id === "Colaboradores" && hasUser
                    ? true
                    : hasPermission;
                hasPermission =
                  category.id === "Parametrización" &&
                    (hasGeneral || hasEstado || hasRoles)
                    ? true
                    : hasPermission;
                hasPermission =
                  (category.id === "Acreditaciones" ||
                    category.id === "Billeteras" || category.id === "Confirmar cobros")
                    &&
                    (hasToTransfers || hasRedyToTransfers || hasTransfereds)
                    ? true
                    : hasPermission;
                hasPermission =
                  category.id === "Centro de Facturación"
                    ? true
                    : hasPermission;
                hasPermission =
                  category.id === "Resumen" ? true : hasPermission;
                hasPermission =
                  category.id === "Usuarios" &&
                    (hasUserActive || hasUserInactive || hasUserPending)
                    ? true
                    : hasPermission;
                hasPermission =
                  category.id === "Comercios" && hasBusiness
                    ? true
                    : hasPermission;
                //Pendientes
                hasPermission =
                  category.id === "Acreditaciones" && hasUser
                    ? true
                    : hasPermission;
                hasPermission =
                  category.id === "Facturas" && hasUser
                    ? true
                    : hasPermission;
                return (
                  hasPermission && (
                    <React.Fragment key={index}>
                      {category.header ? (
                        <SidebarSection>{category.header}</SidebarSection>
                      ) : null}

                      {category.children && category.icon ? (
                        <React.Fragment key={index}>
                          <SidebarCategory
                            isOpen={!openRoutes[index]}
                            isCollapsable={true}
                            name={category.id}
                            icon={category.icon}
                            button={true}
                            onClick={() => toggle(index)}
                          />

                          <Collapse
                            in={openRoutes[index]}
                            timeout="auto"
                            unmountOnExit
                          >
                            {category.children.map((route, index) => {
                              let route_name =
                                route.name == "Roles y Privilegios"
                                  ? "Roles"
                                  : route.name == "Solicitudes para Realizar" ||
                                    route.name == "Para Realizar" ||
                                    route.name == "Pendientes de Cierre" ||
                                    route.name == "Realizadas"
                                    ? "Acreditaciones"
                                    : route.name == "Activos" ||
                                      route.name == "Pasivos" ||
                                      route.name == "Pendientes"
                                      ? "Usuarios"
                                      : route.name == "Para emitir" ||
                                        route.name == "Pendientes de pago" ||
                                        route.name == "Pagadas" ||
                                        route.name == "Todas las facturas" ||
                                        route.name == "Aviso de pago"
                                        ? "Centro de Facturación"
                                        : route.name;
                              let subPermiso = permission.filter(
                                (rd) => rd.component === route_name
                              )[0];
                              let hasSubPermission =
                                subPermiso &&
                                subPermiso.permissions.some((p) =>
                                  p.hasOwnProperty("view") ? true : false
                                );
                              return (
                                hasSubPermission && (
                                  <SidebarLink
                                    key={index}
                                    name={route.name}
                                    to={route.path}
                                    icon={route.icon}
                                    badge={route.badge}
                                  />
                                )
                              );
                            })}
                          </Collapse>
                        </React.Fragment>
                      ) : (
                        <SidebarCategory
                          isCollapsable={false}
                          name={category.id}
                          to={category.path}
                          activeClassName="active"
                          component={NavLink}
                          icon={category.icon}
                          exact
                          button
                          badge={getBadge(category, pending_categories, permission)}
                        //permission={true}
                        />
                      )}
                    </React.Fragment>
                  )
                );
              })}
          </Items>
        </List>
      </Scrollbar>
      <SidebarFooter>
        <Grid container spacing={2}>
          <Grid item>
            <SidebarFooterBadge
              overlap="circle"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              variant="dot"
            >
              {auth.photo ? <Avatar alt={auth.name} src={auth.photo} /> : auth.name ?
                <Avatar>{`${auth.name[0]}${auth.lastname[0]}`}</Avatar> : <Avatar src="/static/img/avatars/avatar-1.jpg"></Avatar>}
            </SidebarFooterBadge>
          </Grid>
          <Grid item>
            <SidebarFooterText variant="body2">
              {auth.name} {auth.lastname}
            </SidebarFooterText>
            <SidebarFooterSubText variant="caption">
              {auth.profile}
            </SidebarFooterSubText>
          </Grid>
          <Grid item>
            <UserDropdown />
          </Grid>
        </Grid>
      </SidebarFooter>
    </Drawer>
  );
};

export default withRouter(Sidebar);
