import * as types from "../../constants";
import {
    list as listPermissions,
} from "../../services/permissionsService";

export function permissions(credentials) {
    return async (dispatch) => {
        dispatch({ type: types.LIST_PERMISSIONS_RESTART_RESPONSE });
        dispatch({ type: types.LIST_PERMISSIONS_REQUEST });

        return listPermissions(credentials)
            .then((response) => {
                console.log('actions data');
                console.log(response);
                dispatch({
                    type: types.LIST_PERMISSIONS_SUCCESS,
                    response
                });
            })
            .catch((error) => {
                dispatch({ type: types.LIST_PERMISSIONS_FAILURE, error });
                throw error;
            });
    };
}

