import { useState, useCallback, useEffect } from 'react';
let logoutTimer;
export const useAuth = () => {
    const [token, setToken] = useState(false);
    const [teoIdToken, setTeoIdToken] = useState(false);
    const [admin, setAdmin] = useState(false);
    const [userId, setUserId] = useState(false);
    const [teoId, setTeoId] = useState(false);
    const [name, setName] = useState(null);
    const [lastname, setLastname] = useState(null);
    const [email, setEmail] = useState(null);
    const [profile, setProfile] = useState(null);
    const [tokenExpiration, setTokenExpiration] = useState();
    const [photo, setPhoto] = useState(null)

    const login = useCallback((userId, teoId, admin, token, teoIdToken, name, lastname, email, profile, expiration, photo) => {
        setToken(token);
        setTeoIdToken(teoIdToken);
        setAdmin(admin);
        setUserId(userId);
        setTeoId(teoId);
        setName(name);
        setLastname(lastname);
        setEmail(email);
        setProfile(profile);
        const tokenExpiration = expiration ? new Date(new Date(expiration).getTime()) : new Date(new Date().getTime() + 1000 * 60 * 60);
        setTokenExpiration(tokenExpiration);
        setPhoto(photo)
        localStorage.setItem('userData', JSON.stringify({
            userId: userId,
            teoId: teoId,
            admin: admin,
            token: token,
            teoIdToken: teoIdToken,
            name: name,
            lastname: lastname,
            email: email,
            profile: profile,
            expiration: tokenExpiration.toISOString(),
            photo: photo
        }));
    }, []);

    const logout = useCallback((teoId) => {
        setToken(null);
        setTeoIdToken(null);
        setAdmin(null);
        setUserId(null);
        setTeoId(null);
        setName(null);
        setLastname(null);
        setEmail(null);
        setProfile(null);
        setTokenExpiration(null);
        setPhoto(null);
        localStorage.removeItem('userData');
        if (teoId) {
            window.location.href = `${process.env.REACT_APP_TEOID_URL}/logout?app=teopagos&teoId=${teoId}&redirect=${process.env.REACT_APP_TEOPAGOS_URL}`;
        }
        window.location.href = window.location.origin
    }, []);

    useEffect(() => {
        if (token && tokenExpiration) {
            const duration = tokenExpiration.getTime() - new Date().getTime();
            logoutTimer = setTimeout(logout, duration);
        } else {
            clearTimeout(logoutTimer);
        }
    }, [token, logout, tokenExpiration])

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem('userData'));
        if (storedData &&
            storedData.token &&
            new Date(storedData.expiration) > new Date()) {
            login(storedData.userId,
                storedData.teoId,
                storedData.admin,
                storedData.token,
                storedData.teoIdToken,
                storedData.name,
                storedData.lastname,
                storedData.email,
                storedData.profile,
                new Date(storedData.expiration),
                storedData.photo);
        }
    }, [login]);

    return { token, teoIdToken, admin, login, logout, userId, teoId, name, lastname, email, profile, photo };
}