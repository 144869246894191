import * as types from "../../constants";
const initState = {
  rows: "",
  columns: "",
  totals: "",
  statuses: "",
  methods: "",
  permissions: "",
};

export default function reducer(state = initState, actions) {
  switch (actions.type) {
    case types.INVOICES_RESTART_RESPONSE:
      return {
        ...state,
        rows: "",
        columns: "",
        totals: "",
        statuses: "",
        methods: "",
        permissions: "",
      };

    case types.INVOICES_LIST_SUCCESS:
      console.log("reducers invoices list data");
      console.log(actions.response);
      return {
        ...state,
        rows: actions.response.rows,
        columns: actions.response.columns,
        totals: actions.response.totals,
        statuses: actions.response.statuses,
        methods: actions.response.methods,
        permissions: actions.response.permissions,
      };
    
    case types.INVOICES_UPDATE_SUCCESS:
      console.log("reducers invoices updated data");
      console.log(actions.response);
      return actions.response;

    default:
      return state;
  }
}
