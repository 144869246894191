import * as types from "../../constants";
const initState = {
  rows: "",
  columns: "",
  details: "",
  business: "",
  operations: "",
  activities: "",
  creditings: "",
  products_plan: "",
  csv_url: false,
  amount_available: ""
};

export default function reducer(state = initState, actions) {
  switch (actions.type) {
    case types.USERS_RESTART_RESPONSE:
      return {
        ...state,
        rows: "",
        columns: "",
        business: "",
        details: "",
        operations: "",
        activities: "",
        creditings: "",
        products_plan: "",
        products_plan_initial: "",
        csv_url: false,
        amount_available: ""
      };

    case types.USERS_LIST_SUCCESS:
      console.log("reducers USERS list data");
      console.log(actions.response);
      return {
        ...state,
        rows: actions.response.rows.results,
        columns: actions.response.columns,
        details: actions.response.rows,
        business: actions.response.business,
        operations: actions.response.operations,
        activities: actions.response.activities,
        creditings: actions.response.creditings,
        products_plan: actions.response.products_plan,
        products_plan_initial: actions.response.products_plan_initial,
        csv_url: actions.response.url_datafile,
        amount_available: actions.response.business_amount_available
      };

    default:
      return state;
  }
}
