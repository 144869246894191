import * as types from "../../constants";
const initState = {
  rows: "",
  columns: "",
  options: "",
  details: "",
  csv_url: false
};

export default function reducer(state = initState, actions) {
  switch (actions.type) {
    case types.WALLETS_RESTART_RESPONSE:
      return {
        ...state,
        rows: "",
        columns: "",
        options: "",
        details: "",
        csv_url: false
      };

    case types.WALLETS_LIST_SUCCESS:
      console.log("reducers wallets list data");
      console.log(actions.response);
      return {
        ...state,
        rows: actions.response.rows.results,
        columns: actions.response.columns,
        options: actions.response.options,
        details: actions.response.rows,
        csv_url: actions.response.url_datafile
      };
    case types.WALLETS_UPDATE_SUCCESS:
      console.log("reducers wallets update data");
      console.log(actions.response);
      return {
        ...state,
        rows: actions.response.rows.results,
        success: actions.response.success,
        data: actions.response.data,
        columns: actions.response.columns,
        details: actions.response.rows,
        csv_url: actions.response.url_datafile
      };
    case types.WALLETS_UPDATE_FAILURE:
      console.log("reducers wallets update data");
      console.log(actions.response);
      return {
        ...state,
        success: actions.response.success,
        data: actions.response.data,
        rows: "",
        columns: "",
        details: "",
        csv_url: false
      };

    default:
      return state;
  }
}
