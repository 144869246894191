import * as types from "../../constants";
const initState = {
    rows: "",
    columns: "",
    options: "",
}

export default function reducer(state = initState, actions) {
    switch (actions.type) {
        case types.CONF_GEN_RESTART_RESPONSE:
            console.log('reducers conf restarting response');
            return {
                rows: "",
                columns: "",
                options: "",
            };

        case types.CONF_GEN_LIST_SUCCESS:
            console.log('reducers conf gen list data');
            console.log(actions.response);
            return {
                ...state,
                rows: actions.response.rows,
                columns: actions.response.columns,
                options: actions.response.options,
            };

        default:
            return state;
    }
}
