import * as types from "../../constants";
import { actions as performAction } from "../../services/customerBusinessService";

export function actions(credentials) {
  const { action } = credentials;
  return async (dispatch) => {
    dispatch({ type: types.CUSTOMER_BUSINESS_RESTART_RESPONSE });
    switch (action) {
      case "list":
        dispatch({ type: types.CUSTOMER_BUSINESS_LIST_REQUEST });
        break;
      case "create":
        dispatch({ type: types.CUSTOMER_BUSINESS_CREATE_REQUEST });
        break;
      case "update":
        dispatch({ type: types.CUSTOMER_BUSINESS_UPDATE_REQUEST });
        break;
      case "delete":
        dispatch({ type: types.CUSTOMER_BUSINESS_DELETE_REQUEST });
        break;
    }

    return performAction(credentials)
      .then((response) => {
        console.log("actions " + action + " comercios");
        console.log(response);
        switch (action) {
          case "list":
            dispatch({
              type: types.CUSTOMER_BUSINESS_LIST_SUCCESS,
              response,
            });
            break;
          case "create":
            dispatch({
              type: types.CUSTOMER_BUSINESS_CREATE_SUCCESS,
              response,
            });
            break;
          case "update":
            dispatch({
              type: types.CUSTOMER_BUSINESS_UPDATE_SUCCESS,
              response,
            });
            break;
          case "delete":
            dispatch({
              type: types.CUSTOMER_BUSINESS_DELETE_SUCCESS,
              response,
            });
            break;
        }
      })
      .catch((error) => {
        switch (action) {
          case "list":
            dispatch({ type: types.CUSTOMER_BUSINESS_LIST_FAILURE });
            break;
          case "create":
            console.log(error, "error create customer business actions");
            dispatch({ type: types.CUSTOMER_BUSINESS_CREATE_FAILURE });
            break;
          case "update":
            dispatch({ type: types.CUSTOMER_BUSINESS_UPDATE_FAILURE });
            break;
          case "delete":
            dispatch({ type: types.CUSTOMER_BUSINESS_DELETE_FAILURE });
            break;
        }
        throw error;
      });
  };
}
