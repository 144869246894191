import React from "react";

import async from "../components/Async";

import DashboardIcon from "@material-ui/icons/Dashboard";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SettingsIcon from "@material-ui/icons/Settings";
import ReceiptIcon from "@material-ui/icons/Receipt";
import StoreIcon from "@material-ui/icons/Store";
import AccountBalanceWallet from "@material-ui/icons/AccountBalanceWallet";
import CustomerBusinessEdit from "../pages/customerbusiness/Edit";
import List from '@material-ui/icons/List';

// Landing
const Landing = async(() => import("../pages/presentation/Landing"));

// Guards
const AuthGuard = async(() => import("../components/AuthGuard"));

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));

// Dashboard
const Dashboard = async(() => import("../pages/dashboard"));

// Pages
const Requests = async(() => import("../pages/requests"));
const RequestsDetails = async(() => import("../pages/requests/Details"));
const BusinessConfig = async(() => import("../pages/requests/BusinessConfig"));
const RequestsCategoryDetails = async(() =>
  import("../pages/requests/DetailsCategory")
);
const Transfers = async(() => import("../pages/transfers"));
const TransfersDetails = async(() => import("../pages/transfers/Details"));
const ToTransfers = async(() => import("../pages/transfers/ToTransfers"));
const ToTransferDetails = async(() =>
  import("../pages/transfers/details/ToTransfer")
);
const ReadyToTransfers = async(() =>
  import("../pages/transfers/ReadyToTransfers")
);
const ReadyToTransferDetails = async(() =>
  import("../pages/transfers/details/ReadyToTransfer")
);
const Transfereds = async(() => import("../pages/transfers/Transfereds"));
const ManualToTransfers = async(() =>
  import("../pages/transfers/ManualToTransfers")
);
const ManualToTransferDetails = async(() =>
  import("../pages/transfers/details/ManualToTransfer")
);
const FinantialReport = async(() =>
  import("../pages/operations/FinantialReport")
);
const TransferedDetails = async(() =>
  import("../pages/transfers/details/Transfered")
);
const Colaborators = async(() => import("../pages/colaborators"));
const ColaboratorsDetails = async(() =>
  import("../pages/colaborators/Details")
);
const ConfigurationsGeneral = async(() =>
  import("../pages/configurations/general")
);
const ConfigurationsGeneralDetails = async(() =>
  import("../pages/configurations/general/Details")
);
const ConfigurationsStatuses = async(() =>
  import("../pages/configurations/status")
);
const ConfigurationsStatusesDetails = async(() =>
  import("../pages/configurations/status/Details")
);
const ConfigurationsRoles = async(() => import("../pages/configurations/role"));
const ConfigurationsRolesDetails = async(() =>
  import("../pages/configurations/role/Details")
);
const Movements = async(() => import("../pages/movements"));
const MovementsDetails = async(() => import("../pages/movements/Details"));
const Operations = async(() => import("../pages/operations"));
const OperationsDetails = async(() => import("../pages/operations/Details"));
const Users = async(() => import("../pages/users"));
const UsersDetails = async(() => import("../pages/users/Details"));

const InvoicesDetails = async(() => import("../pages/invoices/Details"));
const ToIssue = async(() => import("../pages/invoices/ToIssue"));
const ToPaid = async(() => import("../pages/invoices/ToPaid"));
const Paid = async(() => import("../pages/invoices/Paid"));
const All = async(() => import("../pages/invoices/All"));
const PaymentNotice = async(() => import("../pages/invoices/PaymentNotice"));
const NewCustomerBusiness = async(() =>
  import("../pages/users/CustomerBusiness")
);
const CustomerBusiness = async(() => import("../pages/customerbusiness"));
const CustomerBusinessPendings = async(() => import("../pages/customerbusiness/pendingsEnable"));
const CustomersBusinessDetails = async(() =>
  import("../pages/customerbusiness/Details")
);
const CustomersBusinessEdit = async(() => import("../pages/customerbusiness/Edit"))
const Wallets = async(() => import("../pages/wallets"));
const dashboardsRoutes = {
  id: "Resumen",
  path: "/dashboard",
  component: Dashboard,
  guard: AuthGuard,
  icon: <DashboardIcon />,
  containsHome: true,
  children: null,
};

//Nuevo sidebar
const pendingTransferRoutes = {
  id: "Acreditaciones",
  path: "/pendings/transfers",
  header: "PENDIENTES",
  guard: AuthGuard,
  component: ToTransfers,
  children: null
}

const pendingInvoicesRoutes = {
  id: "Facturas",
  path: "/pendings/invoices",
  name: "Facturas",
  guard: AuthGuard,
  component: ToIssue,
  children: null
}

const pendingWalletsRoutes = {
  id: "Confirmar cobros",
  path: "/pendings/wallets",
  component: Wallets,
  guard: AuthGuard,
  children: null,
};

const pendingCustomerBusinessRoutes = {
  id: "Comercios",
  path: "/pendings/customerbusiness",
  component: CustomerBusinessPendings,
  guard: AuthGuard,
  children: null,
};


//Nuevo sidebar
const movementsRoutes = {
  id: "Cobros",
  path: "/reports/movements",
  header: "INFORMES",
  guard: AuthGuard,
  component: Movements,
  children: null
}
const transfersRoutes = {
  id: "Acreditaciones",
  path: "/reports/transfers",
  component: Transfers,
  guard: AuthGuard,
  children: null,
};
const invoicesRoutes = {
  id: "Facturas",
  path: "/reports/invoices",
  component: All,
  guard: AuthGuard,
  children: null,
};
const customerBusinessRoutes = {
  id: "Comercios",
  path: "/reports/customersbusiness",
  component: CustomerBusiness,
  guard: AuthGuard,
  children: null,
};
const newUsersRoutes = {
  id: "Usuarios",
  path: "/reports/users",
  component: Users,
  guard: AuthGuard,
  children: null
}
const colaboratorsRoutes = {
  id: "Colaboradores",
  path: "/colaborators",
  header: "CONFIGURACIÓN",
  component: Colaborators,
  guard: AuthGuard,
};
const configRoutes = {
  id: "Parametrización",
  path: "/configurations",
  guard: AuthGuard,
  icon: true,
  component: null,
  children: [
    {
      path: "/configurations/general",
      name: "General",
      guard: AuthGuard,
      component: ConfigurationsGeneral,
    },
    {
      path: "/configurations/status",
      name: "Estados",
      guard: AuthGuard,
      component: ConfigurationsStatuses,
    },
    {
      path: "/configurations/role",
      name: "Roles y Privilegios",
      guard: AuthGuard,
      component: ConfigurationsRoles,
    },
  ],
};

// const requestRoutes = {
//   id: "Habilitaciones",
//   path: "/requests",
//   component: Requests,
//   guard: AuthGuard,
//   icon: <PlaylistAddCheckIcon />,
//   containsHome: true,
//   children: null,
// };

const requestDetailsRoutes = {
  id: "Detalle de habilitacion",
  path: "/requests/details/:id",
  component: RequestsDetails,
  guard: AuthGuard,
  containsHome: false,
  children: null,
};

const requestBusinessConfigRoutes = {
  id: "Configuración Inicial del Negocio",
  path: "/requests/businessconfig/:reqId/:id",
  component: BusinessConfig,
  guard: AuthGuard,
  containsHome: false,
  children: null,
};

const createCustomerBusiness = {
  id: "Crear cuenta comercio",
  path: "/create_customer_business/:userId",
  component: NewCustomerBusiness,
  guard: AuthGuard,
  containsHome: false,
  children: null,
};

const requestCategoryDetailsRoutes = {
  id: "Detalle de la categoria de habilitacion",
  path: "/requests/categorydetails/:id/:ids",
  component: RequestsCategoryDetails,
  guard: AuthGuard,
  containsHome: true,
  children: null,
};

/* const transfersRoutes = {
  id: "Acreditaciones",
  path: "/transfers",
  // component: Transfers,
  guard: AuthGuard,
  icon: <SwapHorizIcon />,
  component: null,
  children: [
    {
      path: "/transfers/manual_to_transfers",
      name: "Solicitudes para Realizar",
      guard: AuthGuard,
      component: ManualToTransfers,
    },
    {
      path: "/transfers/to_transfers",
      name: "Para Realizar",
      guard: AuthGuard,
      component: ToTransfers,
    },
    {
      path: "/transfers/ready_to_transfers",
      name: "Pendientes de Cierre",
      guard: AuthGuard,
      component: ReadyToTransfers,
    },
    {
      path: "/transfers/transfereds",
      name: "Realizadas",
      guard: AuthGuard,
      component: Transfereds,
    },
  ],
}; */

const manualToTransferDetailsRoutes = {
  id: "Detalles de solicitud de acreditación",
  path: "/transfers/manual_to_transfer/:id",
  component: ManualToTransferDetails,
  guard: AuthGuard,
  containsHome: true,
  children: null,
};

const transfersDetailsRoutes = {
  id: "Detalles de Acreditacion",
  path: "/transfers/details/:id",
  component: TransfersDetails,
  guard: AuthGuard,
  containsHome: true,
  children: null,
};

const toTransfersDetailsRoutes = {
  id: "Detalles de acreditacion a realizar",
  path: "/transfers/to_transfer/:id",
  component: ToTransferDetails,
  guard: AuthGuard,
  containsHome: true,
  children: null,
};

const transferedDetailsRoutes = {
  id: "Detalles de acreditacion realizada",
  path: "/transfers/transfered/:id",
  component: TransferedDetails,
  guard: AuthGuard,
  containsHome: true,
  children: null,
};

const redyToTransfereDetailsRoutes = {
  id: "Detalles de acreditacion pendiente de cierre",
  path: "/transfers/redy_to_transfer/:id",
  component: ReadyToTransferDetails,
  guard: AuthGuard,
  containsHome: true,
  children: null,
};

const transferedsDetailsRoutes = {
  id: "Detalles de acreditacion realizada",
  path: "/transfers/transfered/:id",
  component: TransferedDetails,
  guard: AuthGuard,
  containsHome: true,
  children: null,
};

const operationsRoutes = {
  id: "Movimientos",
  path: "/movimientos",
  component: Operations,
  guard: AuthGuard,
  icon: <LocalAtmIcon />,
  containsHome: true,
  children: null,
};

const walletsRoutes = {
  id: "Billeteras",
  path: "/billeteras",
  component: Wallets,
  guard: AuthGuard,
  icon: <AccountBalanceWallet />,
  containsHome: true,
  children: null,
};

const finantialReportRoutes = {
  id: "Informe financiero del negocio",
  path: "/finantial-report/:id",
  component: FinantialReport,
  guard: AuthGuard,
  containsHome: true,
  children: null,
};

const operationsDetailsRoutes = {
  id: "Detalle de Operacion",
  path: "/operations/details/:id",
  component: OperationsDetails,
  guard: AuthGuard,
  containsHome: true,
  children: null,
};



const colaboratorsDetailsRoutes = {
  id: "Detalle de Colaborador",
  path: "/colaborators/details/:id",
  component: ColaboratorsDetails,
  guard: AuthGuard,
  containsHome: true,
  children: null,
};

const usersRoutes = {
  id: "Usuarios",
  path: "/users",
  component: Users,
  guard: AuthGuard,
  icon: <AccountCircleIcon />,
  children: null,
  /* children: [
    {
      path: "/users/active",
      name: "Activos",
      guard: AuthGuard,
      component: Users,
    },
    {
      path: "/users/inactive",
      name: "Pasivos",
      guard: AuthGuard,
      component: Users,
    },
    {
      path: "/users/pending",
      name: "Pendientes",
      guard: AuthGuard,
      component: Requests,
    },
  ], */
};

const usersDetailsRoutes = {
  id: "Usuarios",
  path: "/users/details/:id",
  component: UsersDetails,
  guard: AuthGuard,
  containsHome: true,
  children: null,
};


const customerBusinessDetailsRoutes = {
  id: "Comercios",
  path: "/customersbusiness/details/:id",
  component: CustomersBusinessDetails,
  guard: AuthGuard,
  containsHome: true,
  children: null,
};
const customerBusinessEditRoutes = {
  id: "Comercios",
  path: "/customersbusiness/edit/:id",
  component: CustomersBusinessEdit,
  guard: AuthGuard,
  containsHome: true,
  children: null,
};

const enableCustomerBusinessCharge = {
  id: "Comercios",
  path: "/customersbusiness/enable_charge/:id",
  component: NewCustomerBusiness,
  guard: AuthGuard,
  containsHome: false,
  children: null,
};

const configGeneralDetailsRoutes = {
  id: "Detalles de Configuracion General",
  path: "/configurations/general/details/:id",
  component: ConfigurationsGeneralDetails,
  guard: AuthGuard,
  containsHome: true,
  children: null,
};

const configStatusDetailsRoutes = {
  id: "Detalles de Configuracion de Estados",
  path: "/configurations/status/details/:id",
  component: ConfigurationsStatusesDetails,
  guard: AuthGuard,
  containsHome: true,
  children: null,
};

const configRolesDetailsRoutes = {
  id: "Detalles de Configuracion de Roles y Permisos",
  path: "/configurations/role/details/:id",
  component: ConfigurationsRolesDetails,
  guard: AuthGuard,
  containsHome: true,
  children: null,
};

/* const invoicesRoutes = {
  id: "Centro de Facturación",
  path: "/invoices",
  guard: AuthGuard,
  icon: <ReceiptIcon />,
  component: null,
  children: [
    {
      path: "/invoices/to_issue",
      name: "Para emitir",
      guard: AuthGuard,
      component: ToIssue,
    },
    {
      path: "/invoices/pay_pending",
      name: "Pendientes de pago",
      guard: AuthGuard,
      component: ToPaid,
    },
    {
      path: "/invoices/paid",
      name: "Pagadas",
      guard: AuthGuard,
      component: Paid,
    },
    {
      path: "/invoices/all",
      name: "Todas las facturas",
      guard: AuthGuard,
      component: All,
    },
      {
      path: "/invoices/payment_notice",
      name: "Aviso de pago",
      guard: AuthGuard,
      component: PaymentNotice,
    },
  ],
}; */

const invoicesDetailsRoutes = {
  id: "Detalles de Factura",
  path: "/invoices/details/:id",
  component: InvoicesDetails,
  guard: AuthGuard,
  containsHome: true,
  children: null,
};

const authRoutes = {
  id: "Autenticacion",
  path: "/",
  children: [
    {
      path: "/signin",
      name: "Ingresar",
      component: SignIn,
    },
    {
      path: "/signup",
      name: "Registrarme",
      component: SignUp,
    },
    {
      path: "/reset-password",
      name: "Resetear Contraseña",
      component: ResetPassword,
    },
    {
      path: "/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

const landingRoutes = {
  id: "Landing Page",
  path: "/",
  header: "Docs",
  component: Landing,
  children: null,
};

// Routes using the Dashboard layout
export const dashboardRoutes = [
  dashboardsRoutes,
  pendingTransferRoutes,
  pendingInvoicesRoutes,
  pendingWalletsRoutes,
  pendingCustomerBusinessRoutes,
  movementsRoutes,
  transfersRoutes,
  invoicesRoutes,
  customerBusinessRoutes,
  newUsersRoutes,
  colaboratorsRoutes,
  configRoutes,
  configGeneralDetailsRoutes,
  configStatusDetailsRoutes,
  configRolesDetailsRoutes,
  // requestRoutes,
  requestDetailsRoutes,
  requestBusinessConfigRoutes,
  requestCategoryDetailsRoutes,
  transfersRoutes,
  manualToTransferDetailsRoutes,
  transfersDetailsRoutes,
  toTransfersDetailsRoutes,
  transferedsDetailsRoutes,
  operationsRoutes,
  walletsRoutes,
  operationsDetailsRoutes,
  colaboratorsDetailsRoutes,
  usersRoutes,
  usersDetailsRoutes,
  customerBusinessDetailsRoutes,
  customerBusinessEditRoutes,
  transferedDetailsRoutes,
  redyToTransfereDetailsRoutes,
  invoicesDetailsRoutes,
  finantialReportRoutes,
  createCustomerBusiness,
  enableCustomerBusinessCharge,
];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [landingRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [
  dashboardsRoutes,
  pendingTransferRoutes,
  pendingInvoicesRoutes,
  pendingWalletsRoutes,
  pendingCustomerBusinessRoutes,
  movementsRoutes,
  transfersRoutes,
  invoicesRoutes,
  customerBusinessRoutes,
  newUsersRoutes,
  colaboratorsRoutes,
  configRoutes,
  // requestRoutes,
 /*  operationsRoutes */,
  /*  walletsRoutes, */
];
