import { createContext } from 'react';

export const AuthContext = createContext({
    admin: false,
    isLoggedIn: false,
    userId: null,
    teoId: null,
    token: null,
    teoIdToken: null,
    name: null,
    lastname: null,
    email: null,
    profile: null,
    expiration: null,
    photo: null,
    login: () => { },
    logout: () => { }
});