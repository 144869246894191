import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import DateFnsUtils from "@date-io/date-fns";

import { ThemeProvider } from "styled-components/macro";
import { create } from "jss";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  jssPreset,
} from "@material-ui/core/styles";

import createTheme from "./theme";
import Routes from "./routes/Routes";
import { useAuth } from "./redux/store/auth-hook";
import { AuthContext } from "./redux/store/auth-context";
import * as locales from '@material-ui/core/locale';
// import ClientSocket from "./services/wsService";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

function App() {
  const theme = useSelector((state) => state.themeReducer);
  const [locale, setLocale] = React.useState('esES');
  const {
    token,
    teoIdToken,
    admin,
    login,
    logout,
    userId,
    teoId,
    name,
    lastname,
    email,
    profile,
    photo
  } = useAuth();

  return (
    <React.Fragment>
      <Helmet
        titleTemplate="%s | TeoPagos"
        defaultTitle="TeoPagos"
      />
      <AuthContext.Provider
        value={{
          isLoggedIn: !!token,
          admin: admin,
          token: token,
          teoIdToken: teoIdToken,
          userId: teoId,
          teoId: teoId,
          name: name,
          lastname: lastname,
          email: email,
          profile: profile,
          photo: photo,
          login: login,
          logout: logout,
        }}
      >
        <StylesProvider jss={jss}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiThemeProvider theme={createTheme(theme.currentTheme, locales[locale])}>
              <ThemeProvider theme={createTheme(theme.currentTheme, locales[locale])}>
                {/* <ClientSocket username={teoIdToken} /> */}
                <Routes />

              </ThemeProvider>
            </MuiThemeProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </AuthContext.Provider>
    </React.Fragment>
  );
}

export default App;
